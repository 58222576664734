<template>
  <div class="row bg-white">
    <!--
    <div class="flex1 tablist row">
      <div class="tab-control-item row flex-center" v-for="(item, index) in tabs" :key="index">{{item.name}}</div>
    </div>
    <div class="more"></div>
    
    <ul class="row align-center local-nav h80 bg-white">
      <li class="tab-item" v-for="(item,index) in tabs" :key="index" :class="{active: currentIndex == index}" @click="tabClick(index)">
          <span class="font32 fontwb">{{item.name}}</span>
      </li>
      <li  class="tab-item">
          <a href="#">
              <span class="more"></span>
          </a>
      </li>
    </ul>
    <div class="more"></div>
    -->
    <div class="flex1 slide-box">
      <div class="slide-item row flex-center" @click="tabClick(-1)" :class="{active: currentIndex == -1}">
        <span>橙子街</span>
      </div>
      <div class="slide-item  row flex-center" v-for="(item,index) in tabs" :key="index" :class="{active: currentIndex == index}" @click="tabClick(index)">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="more" @click="onMore"></div>
  </div>
  
    
</template>

<script>
export default {
  name: 'HomeTab',
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
  },
  methods: {
    tabClick(index) {
      this.currentIndex = index;
      this.$emit('tabClick',index);
    },
    onMore(){
      this.$emit('more');
      /*
      this.$router.replace({
        path: '/category',
        query: {}
      });
      */
    }
  },
  data() {
    return {
      currentIndex: -1
    }
  }
}
</script>

<style scoped>
.slide-box{
    display: -webkit-box;
    overflow-x: scroll;
    -webkit-overflow-scrolling:touch;
    font-size: .373333rem;
}
.slide-item{
    height: 44px;
}
.slide-item span{
  padding-left: 10px;
  padding-right: 10px;
}
.active span {
    border-bottom: 2px solid #F62341;
  }
.active{
  color: #F62341;
}
.more{
    width: 54px;
    height: 44px;
    background: url(~images/gengduo_icon.png) no-repeat;
    background-size: 54px 44px;
}
</style>
